<template>
    <div>
        <!-- full Title -->
        <div class="full-title">
            <div class="container">
                <!-- Page Heading/Breadcrumbs -->
                <h1 class="mt-4 mb-3"> Cursos - Pós-Graduação</h1>
                <div class="breadcrumb-main">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'home'}">Início</router-link>
                        </li>
                        <li class="breadcrumb-item active"> Cursos </li>
                        <li class="breadcrumb-item active"> Pós-Graduação </li>
                    </ol>
                </div>
            </div>
        </div>

        <div id="overviews" class="section wb">
            <div class="container">
                <div class="row" v-for="(cr, index) in course" :key="index"> 
                    <div class="col-lg-9 blog-post-single">
                        <div class="blog-item">
                            <div class="image-blog img-course">
                                <img v-if="cr.image"
                                    class="img-fluid" 
                                    :src="'https://uniminasead.com.br/uploads/'+cr.image" 
                                    :alt="cr.titulo">
                                <img v-else 
                                    class="img-fluid" 
                                    src="https://uniminasead.com.br/uploads/cursos/default.jpg" 
                                    :alt="cr.titulo">
                            </div>
                            <div class="post-content">
                                <div class="blog-title">
                                    <h2>{{ cr.titulo }}</h2>
                                </div>
                                <div class="blog-desc">
                                    <span v-html="cr.conteudo"></span>
                                </div>							
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-12 right-single">
                        <div class="widget-categories">
                            <h3 class="widget-title">Taxa de Matrícula</h3>
                            <ul>
                                <li>Valor <span>{{ cr.matricula }}</span></li>
                            </ul>
                        </div>
                        <div class="widget-categories">
                            <h3 class="widget-title">Investimento</h3>
                            <ul>
                                <li>1x <span>{{ cr.opcao1 }}</span></li>
                                <li>6x <span>{{ cr.opcao6 }}</span></li>
                                <li>12x <span>{{ cr.opcao12 }}</span></li>
                                <li>18x <span>{{ cr.opcao18 }}</span></li>
                                <li>20x <span>{{ cr.opcao20 }}</span></li>
                                <li>22x <span>{{ cr.opcao22 }}</span></li>
                                <li>24x <span>{{ cr.opcao24 }}</span></li>
                            </ul>
                        </div>
                        <div class="widget-categories">
                            <h3 class="widget-title">Material Didático</h3>
                            <ul>
                                <li>1x <span>{{ cr.mat1 }}</span></li>
                                <li>2x <span>{{ cr.mat2 }}</span></li>
                                <li>3x <span>{{ cr.mat3 }}</span></li>
                                <li>4x <span>{{ cr.mat4 }}</span></li>
                                <li>OnLine <span>{{ cr.mat5 }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: ['courseUrl'],
    mounted() {
        this.getCourse(this.courseUrl)
            .catch(response => this.$vToastify.error('Curso não encontrado', 'Erro'))
    },

    computed: {
        ...mapState({
            course: state => state.course.items
        }),
        
    },

    methods: {
        ...mapActions([
            'getCourse',
        ])
    }
}
</script>