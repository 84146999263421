<template>
    <div>
        <!-- full Title -->
        <div class="full-title">
            <div class="container">
                <!-- Page Heading/Breadcrumbs -->
                <h1 class="mt-4 mb-3"> Pós-Graduação</h1>
                <div class="breadcrumb-main">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="{name: 'home'}">Início</router-link>
                        </li>
                        <li class="breadcrumb-item active"> Pós-Graduação </li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="container">
            <!-- About Content -->
            <div class="about-main">
                <div class="row">
                    <div class="col-lg-12" v-for="(pg, index) in page" :key="index">
                        <h2>{{ pg.title }}</h2>
                        <span v-html="pg.content"></span>
                    </div>
                </div>
                <!-- /.row -->
            </div>
        </div> 
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getPage('posgraduacao')
            .catch(response => this.$vToastify.error('Falha ao carregar informações', 'Erro'))
    },

    computed: {
        ...mapState({
            page: state => state.page.items
        })
    },

    methods: {
        ...mapActions([
            'getPage',
        ])
    }
}
</script>