import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/pages/Home';
import About from '@/pages/About';
import Courses from '@/pages/Courses';
import Course from '@/pages/Course';
import Contact from '@/pages/Contact';
import Registration from '@/pages/Registration';
import Postgraduate from '@/pages/Postgraduate';
import RelationshipCenter from '@/pages/RelationshipCenter';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import PageNotFound from '@/pages/PageNotFound';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
            title: ''
        }
    },
    {
        path: '/institucional',
        component: About,
        name: 'institucional',
        meta: {
            title: ' | Institucional'
        }
    },
    {
        path: '/cursos/pos-graducao',
        component: Courses,
        name: 'cursos',
        meta: {
            title: ' | Cursos - Pós Graduação'
        }
    },
    {
        path: '/cursos/pos-graducao/:courseUrl',
        component: Course,
        name: 'curso',
        props: true,
        meta: {
            title: ' | Cursos - Pós Graduação'
        }
    },
    {
        path: '/contato',
        component: Contact,
        name: 'contato',
        meta: {
            title: ' | Contato'
        }
    },
    {
        path: '/matricula',
        component: Registration,
        name: 'matricula',
        meta: {
            title: ' | Matrícula'
        }
    },
    {
        path: '/pos-graduacao',
        component: Postgraduate,
        name: 'posgraduacao',
        meta: {
            title: ' | Informações sobre a Pós Graduação'
        }
    },
    {
        path: '/central-de-relacionamento',
        component: RelationshipCenter,
        name: 'centralderelacionamento',
        meta: {
            title: ' | Central de Relacionamento'
        }
    },
    {
        path: '/politica-de-privacidade',
        component: PrivacyPolicy,
        name: 'politicadeprivacidade',
        meta: {
            title: ' | Política de Privacidade'
        }
    },
    {
        path: '*',
        component: PageNotFound,
        meta: {
            title: ' | Página não encontrada'
        }
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    linkActiveClass: "active",
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    document.title = 'Uniminas EAD' + to.meta.title;
    next()
})

export default router