import Vue from 'vue'
import Vuex from 'vuex'

import courses from './modulos/courses/courses'
import categories from './modulos/courses/categories'
import course from './modulos/courses/course'
import page from './modulos/page'
import configs from './modulos/configs'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        courses,
        categories,
        course,
        page,
        configs,
    },
    state: {
        preloader: false,
        popup: false,
        cookies: localStorage.acceptCookies ? false : true
    },
    mutations: {
        SET_PRELOADER (state, status){
            state.preloader = status
        },
        SET_COOKIES (state, status){
            state.cookies = status
        },
        SET_POPUP (state, status){
            state.popup = status
        }
    }
})

export default store