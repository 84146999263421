import axios from 'axios'
import { courses } from '@/bootstrap'

const RESOURCE = 'categorias'

export default {
    state: {
        items: {
            data: []
        }
    },

    actions: {
        getCategories ({ commit }) {
            return courses.get(`${RESOURCE}`,{
                timeout: 5000
            })
            .then(response => commit('SET_CATEGORIES', response.data))
        }
    },

    mutations: {
        SET_CATEGORIES (state, categories){
            state.items = categories
        }
    },

    getters: {

    },
}