import axios from 'axios'
import { courses } from '@/bootstrap'

const RESOURCE = 'cursos'

export default {
    state: {
        items: {
            data: []
        }
    },

    actions: {
        getCourse ({ commit }, courseUrl) {
            commit('SET_PRELOADER', true)
            commit('SET_COURSE', [])

            return courses.get(`${RESOURCE}?curso=${courseUrl}`,{ timeout: 5000 })
                .then(response => commit('SET_COURSE', response.data))
                .finally(() => commit('SET_PRELOADER', false))
        }
    },

    mutations: {
        SET_COURSE (state, course){
            state.items = course
        }
    },

    getters: {

    },
}