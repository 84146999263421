<template>
    <div>
       <header class="slider-main">
            <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner" role="listbox">
                <!-- Slide One - Set the background image for this slide in the line below -->
                <div class="carousel-item active" :style="{'background-image': 'url(' + require('@/assets/images/slider-01.jpg') + ')'}">
                    <div class="carousel-caption d-none d-md-block">
                        <h3>Bem-Vindo(a) a Uniminas EAD</h3>
                        <p>Realizando sonhos</p>
                    </div>
                </div>
                </div>
            </div>
        </header>
        <!-- Page Content -->
        <div class="container">        
            <!-- About Section -->
            <div class="about-main">
                <div class="row">
                <div class="col-lg-6" v-for="(pg, index) in page" :key="index">
                    <h2>{{ pg.title }}</h2>
                    <span v-html="pg.content.substr(0, 500)+'...'"></span>
                    <router-link class="btn btn-primary" :to="{name: 'institucional'}"><span>Leia Mais</span></router-link>
                </div>
                <div class="col-lg-6">
                    <img class="img-fluid rounded" src="@/assets/images/about-img.jpg" alt="" />
                </div>
                </div>
                <!-- /.row -->
            </div>
        </div>	
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getPage('quemsomos')
            .catch(response => this.$vToastify.error('Falha ao carregar institucional', 'Erro'))
    },

    computed: {
        ...mapState({
            page: state => state.page.items
        })
    },

    methods: {
        ...mapActions([
            'getPage',
        ])
    }
}
</script>