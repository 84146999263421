import axios from 'axios'
import { base } from '@/bootstrap'

const RESOURCE = 'pagina'

export default {
    state: {
        items: {
            data: []
        }
    },

    actions: {
        getPage ({ commit }, page) {
            commit('SET_PRELOADER', true)
            return base.get(`${RESOURCE}`+'/'+`${page}`,{
                timeout: 5000
            })
            .then(response => commit('SET_PAGE', response.data))
            .finally(() => commit('SET_PRELOADER', false))
        }
    },

    mutations: {
        SET_PAGE (state, page){
            state.items = page
        }
    },

    getters: {

    },
}