import axios from 'axios'
import { courses } from '@/bootstrap'

const RESOURCE = 'cursos'

export default {
    state: {
        items: {
            data: {}
        }
    },

    actions: {
        getCourses ({ commit }, params) {
            commit('SET_PRELOADER', true)
            commit('SET_COURSES', [])

            return courses.get(`${RESOURCE}`,{ params, timeout: 150000 })
                .then(response => commit('SET_COURSES', response.data))
                .finally(() => commit('SET_PRELOADER', false))
        }
    },

    mutations: {
        SET_COURSES (state, courses){
            state.items = courses
        }
    },

    getters: {

    },
}