import axios from 'axios'
import { base } from '@/bootstrap'

const RESOURCE = 'configs'

export default {
    state: {
        items: {
            data: []
        }
    },

    actions: {
        getConfigs ({ commit }) {
            return base.get(`${RESOURCE}`,{
                timeout: 5000
            })
            .then(response => commit('SET_CONGIFS', response.data))
        }
    },

    mutations: {
        SET_CONGIFS (state, configs){
            state.items = configs
        }
    },

    getters: {

    },
}