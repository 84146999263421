<template>
    <div>
        <!-- Contact Us -->
        <div class="touch-line">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                    <p>Entre em contato conosco para esclarecer suas dúvidas, solicitar suporte ou enviar sugestões.</p>
                    </div>
                    <div class="col-md-4">
                    <a class="btn btn-lg btn-secondary btn-block" href="#"> Contato </a>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- /.container -->
        <!--footer starts from here-->
        <footer class="footer">
            <div class="container bottom_border">
                <div class="row" v-for="(config, index) in configs" :key="index">
                    <div class="col-lg-4 col-md-6 col-sm-6 col">
                        <h5 class="headin5_amrc col_white_amrc pt2"></h5>
                        <!--headin5_amrc-->
                        <p style="margin-bottom:100px"><img src="@/assets/images/logo.png" alt="" width="150px"/></p>
                        <ul class="footer-social">
                            <li v-if="config.facebook"><a :href="config.facebook" target="_blank"><span class="hb hb-xs spin hb-facebook"><i class="fab fa-facebook-f"></i></span></a></li>
                            <li v-if="config.instagram"><a :href="config.instagram"  target="_blank"><span class="hb hb-xs spin hb-instagram"><i class="fab fa-instagram"></i></span></a></li>
                            <li v-if="config.twitter"><a :href="config.twitter" target="_blank"><span class="hb hb-xs spin hb-twitter"><i class="fab fa-twitter"></i></span></a></li>
                            <li v-if="config.youtube"><a :href="config.youtube" target="_blank"><span class="hb hb-xs spin hb-youtube"><i class="fab fa-youtube"></i></span></a></li>
                        </ul>
                    </div>	
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <h5 class="headin5_amrc col_white_amrc pt2">Links Úteis</h5>
                        <!--headin5_amrc-->
                        <ul class="footer_ul_amrc">
                            <li><router-link :to="{name: 'posgraduacao'}"><i class="fas fa-long-arrow-alt-right"></i>Pós Graduação</router-link></li>
                            <li><router-link :to="{name: 'politicadeprivacidade'}"><i class="fas fa-long-arrow-alt-right"></i>Política de Privacidade</router-link></li>
                            <li><router-link :to="{name: 'centralderelacionamento'}"><i class="fas fa-long-arrow-alt-right"></i>Central de Relacionamento</router-link></li>
                            <li><router-link :to="{name: 'contato'}"><i class="fas fa-long-arrow-alt-right"></i>Contato</router-link></li>
                        </ul>
                        <!--footer_ul_amrc ends here-->
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <h5 class="headin5_amrc col_white_amrc pt2">Contatos</h5>

                        <ul class="footer-links">
                            <li>{{ config.email }}</li>
                            <li>{{ config.cnpj }}</li>
                            <li>{{ config.endereco }}</li>
                            <li>{{ config.celular }}</li>
                        </ul><!-- end links -->
                    </div><!-- end col -->
                    <a v-if="config.celular" :href="'https://api.whatsapp.com/send?phone='+config.celular" target="_blank">
                        <img class="whatsapp" src="https://images.tcdn.com.br/static_inst/integracao/imagens/whatsapp.png">
                    </a>
                </div>
            </div>
            <div class="container">
                <p class="copyright text-center">Todos os direitos reservados. &copy; 2021</p>
            </div>
        </footer>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getConfigs()
            .catch(response => this.$vToastify.error('Falha ao carregar configurações', 'Erro'))
    },

    computed: {
        ...mapState({
            configs: state => state.configs.items
        })
    },

    methods: {
        ...mapActions([
            'getConfigs',
        ])
    },
}
</script>