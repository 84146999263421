import axios from 'axios'
import { URI_BASE_API } from '@/configs/api'
import { URI_COURSES_API } from '@/configs/api'


export const base = axios.create({
    baseURL: URI_BASE_API
});

export const courses = axios.create({
    baseURL: URI_COURSES_API
});