<template>
    <div>
        <!-- Top Bar -->
        <div class="top-bar">
            <div class="container">
                <div class="row" v-for="(config, index) in configs" :key="index">
                    <div class="col-lg-0">
                        <div class="social-media">
                            <ul>
                                 <li v-if="config.facebook"><a :href="config.facebook" target="_blank"><span><i class="fab fa-facebook-f"></i></span></a></li>
                                 <li v-if="config.instagram"><a :href="config.instagram" target="_blank"><span><i class="fab fa-instagram"></i></span></a></li>
                                 <li v-if="config.twitter"><a :href="config.twitter" target="_blank"><span><i class="fab fa-twitter"></i></span></a></li>
                                 <li v-if="config.youtube"><a :href="config.youtube" target="_blank"><span><i class="fab fa-youtube"></i></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="contact-details">
                            <ul>
                                <li><i class="fas fa-phone fa-rotate-90"></i> {{ config.celular }}</li>
                                <li>
                                    <a class="btn btn-primary" style="margin-left:40px;" target="_blank" href="https://ava.uniminasead.com.br"><span><i class="fa fa-user"></i> SOU ALUNO</span></a>
                                </li>
                                <li>
                                    <a class="btn btn-primary" style="margin-left:40px;" target="_blank" href="https://universa.uniminasead.com.br/financeiro/painel/titulos"><span><i class="fa fa-file"></i> 2 VIA DO BOLETO</span></a>
                                </li>
                                <li>
                                    <router-link class="btn btn-primary" style="margin-left:40px; background: #FFF; color: #61af33;" :to="{name: 'centralderelacionamento'}"><span>Central de Relacionamento</span></router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Navigation -->
        <nav class="navbar navbar-expand-lg navbar-dark bg-light top-nav">
            <div class="container">
                <a class="navbar-brand" href="index.html">
                    <img src="@/assets/images/logo.png" alt="logo" width="200px"/>
                </a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="fas fa-bars"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'home'}" exact>Início</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'institucional'}">Institucional</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'cursos'}">Pós Graduação</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'contato'}">Contato</router-link>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav navbar-right">
                        <li>
                            <router-link class="btn btn-primary" style="margin-left:40px;margin-top: 3px;" :to="{name: 'matricula'}"><span>Matricule-se</span></router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    mounted() {
        this.getConfigs()
            .catch(response => this.$vToastify.error('Falha ao carregar configurações', 'Erro'))
    },

    computed: {
        ...mapState({
            configs: state => state.configs.items
        })
    },

    methods: {
        ...mapActions([
            'getConfigs',
        ])
    },
}
</script>